export const SCHEME_CODE = 'AXISMotor';
export const OCCUPATION_FAMILY_CODE_MOTOR = 'Motor';
export const OCCUPATION_FAMILY_CODE_TRADE = 'Motor Traders';

export const VAN = 'Van Insurance';
export const MOTOR_FLEET = 'Motor Fleet Insurance';
export const TRUCK_HGV = 'Truck & HGV Insurance';
export const BUS_COACH = 'Bus & Coach Insurance';
export const MINIBUS = 'Minibus Insurance';
export const COURIER = 'Courier Insurance';
export const TAXI = 'Taxi Insurance';
export const MOTOR_TRADE = 'Motor Trade Insurance';
export const FOOD_VEHICLE =
  'Ice Cream, Catering & Food Truck Vehicle Insurance';
export const AGRICULTURAL_FARM = 'Agricultural & Farm Vehicle Insurance';
export const HORSEBOX = 'Horsebox Insurance';
export const EXECUTIVE_CHAUFFEUR = 'Executive & Chauffeur Insurance';
export const SPECIALIST_VEHICLE = 'Other Business & Specialist Vehicles';
